import * as React from "react";
import { Helmet } from "react-helmet";


const Meta = () => {
    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content="High quality Service and Consultancy on Technology, E-Commerce & Digital Marketing in fast-paced world to improve your business and enable growth." />
                <meta name="keywords" content="consultancy, technology, digital transformation, e-commerce, digital marketing" />
                <meta name="robots" content="all,follow" />
                <link rel="preconnect" href="https://hq.artysmm.com/graphql/" />
                <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname + (window.location.pathname.substr(-1)!== '/' ? '/' : '')} />

                <meta property="og:site_name" content="Artysmm" />
                <meta property="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname + (window.location.pathname.substr(-1)!== '/' ? '/' : '')} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:image" content="/static/artysmm/brand/Artysmm_Logo-1-Square.jpg" />
                <meta property="og:title" content="Artysmm Technology & Consultancy" />
                <meta property="og:description" content="High quality Service and Consultancy on Technology, E-Commerce & Digital Marketing in fast-paced world to improve your business and enable growth." />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@ArtysmmCo" />
                <meta name="twitter:creator" content="@ArtysmmCo" />
            </Helmet>
        </React.Fragment>
    );
};

export default Meta
