import React from 'react';
import CookieConsent from "react-cookie-consent";
import { HashLink as Link } from 'react-router-hash-link';
import { setCookie, getCookie } from "./cookie-functions";


const Cookie = () =>  {
    if (!getCookie("ArtysmmCookieConsent")) {
        return (
            <React.Fragment>
                <CookieConsent
                    location="bottom"
                    buttonText="Agree"
                    cookieName="ArtysmmCookieConsent"
                    acceptOnScroll={true}
                    acceptOnScrollPercentage={20}
                    buttonClasses="btn btn-primary"
                    containerClasses=""
                    style={{ background: "#212327" ,fontSize: "12pt", }}
                    buttonStyle={{ fontSize: "12pt", paddingLeft: "20px", marginLeft: "20px", paddingRight: "20px", marginRight: "20px", position: 'relative' }}
                    extraCookieOptions={{ domain: "artysmm.com" }}
                    expires={90}
                    onAccept={({ acceptedByScrolling }) => {
                        if (acceptedByScrolling) {
                            setCookie("ArtysmmCookieConsent", true, 90);
                        } else {
                            setCookie("ArtysmmCookieConsent", true, 90);
                        }
                    }}
                >
                    Artysmm uses cookies in order to provide you the best experience. By browsing this site, you acknowledge that you have read and understand its <Link to="/privacy-policy/#cookies">Cookie Policy</Link>, <Link to="/privacy-policy/">Privacy Policy</Link> and <Link to="/terms-conditions/">Terms & Conditions</Link>.<br />
                </CookieConsent>
            </React.Fragment>
        );
    } else {
        return null;
    }
}

export default Cookie;
