import { useEffect, useState } from "react";
import { register, unregister } from "register-service-worker";


export const useServiceWorker = ({ timeout = 1000 }) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [registration, setRegistration] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (registration) {
        registration.update();
      }
    }, timeout);
    return () => clearInterval(interval);
  }, [registration]);

  const registered = (registration) => setRegistration(registration);
  const updated = () => setUpdateAvailable(true);

  useEffect(() => {
    if (!process.env.SERVICE_WORKER_EXISTS) {
      unregister();
    } else {
      register("/service-worker.js", { registered, updated });
      return () => unregister();
    }
  }, []);

  return { updateAvailable };
};
