import React from 'react';
import { render } from 'react-dom';
import { hot } from "react-hot-loader";
import App from './App';
import ServiceWorkerProvider from './serviceWorker/serviceWorkerProvider';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'


const httpLink = createHttpLink({
    uri: process.env.API_URI,
    credentials: 'same-origin',
})

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
})

const startApp = async () => {

  const Root = hot(module)(() => {
    return (
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    );
  });

  render(
    <ServiceWorkerProvider timeout={60000}>
      <Root />
    </ServiceWorkerProvider>,
    document.getElementById("root")
  );

  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept();
  }
};

startApp();
