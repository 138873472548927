import React, { Component, Suspense } from 'react';
import Layout from './components/Layout/';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import redirections from './redirections';
import routes from './routes';
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';
import PageError from './pages/Pages/PageError';
import Cookie from './components/Shared/Cookie';


function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    }
  };
}

class App extends Component {

  Loader = () => {
    return (
      <div id="preloader">
          <div id="status">
              <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
              </div>
          </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback = {this.Loader()} >
            <Switch>
              {redirections.map((route, idx) =>
                  <Route exact strict path={route.path} key={idx} render={props => <Redirect to={route.redirect} />} status={301} />
              )}
              <Route exact strict path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`}/>} status={301} />
              <Route exact strict path="/:url*(//+)" render={props => <Redirect to={`${props.location.pathname.replace(/\/+$/, "")}/`}/>} status={301} />
              {routes.map((route, idx) =>
                  <Route exact path={route.path} component={withLayout(route.component)} key={idx} />
              )}
              <Route component={PageError} />
            </Switch>
            <Cookie />
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
