import React from "react";
import { useServiceWorker } from "./useServiceWorker";
import { ServiceWorkerContext } from "./serviceWorkerContext";


const ServiceWorkerProvider = ({
  children,
  timeout,
}) => {
  const context = useServiceWorker({ timeout });
  return (
    <ServiceWorkerContext.Provider value={context}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export default ServiceWorkerProvider
