const redirections = [

  { path: '/our-people/akin/', redirect: '/our-people/' },
  { path: '/e-commerce/google-play-ranking-factors-uncovered/', redirect: '/digital-marketing/google-play-ranking-factors-uncovered/' },
  { path: '/e-commerce/how-ecommerce-brands-can-use-twitter/', redirect: '/digital-marketing/how-ecommerce-brands-can-use-twitter/' },
  { path: '/authors/akin/', redirect: '/our-people/' },
  { path: '/authors/emre/', redirect: '/our-people/emre/' },
  { path: '/authors/olcay/', redirect: '/our-people/olcay/' },
  { path: '/authors/', redirect: '/our-people/' },

];

export default redirections;