import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import {Helmet} from "react-helmet";

// Images
import img404 from '../../assets/media/images/404.png';


class PageError extends Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Artysmm &mdash; Page Not Found</title>
                    <meta name="description" content="Artysmm page you are looking for does not exist. Please check your url and try again. If you are sure that url is correct and still does not work, let us know." />
                    <meta name="keywords" content="Artysmm, Error, Page, Not Found" />
                    <meta name="robots" content="noindex" />
                    <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname + (window.location.pathname.substr(-1)!== '/' ? '/' : '')} />

                    <meta property="og:site_name" content="Artysmm" />
                    <meta property="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname + (window.location.pathname.substr(-1)!== '/' ? '/' : '')} />
                    <meta property="og:title" content="Artysmm — Page Not Found" />
                    <meta property="og:description" content="Artysmm page you are looking for does not exist. Please check your url and try again. If you are sure that url is correct and still does not work, let us know." />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="/static/artysmm/brand/Artysmm_Logo-1.jpg" />
                </Helmet>

                <section className="bg-home d-flex align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8} md={12} className="text-center">
                                <img src={img404} className="img-fluid" alt="" />
                                <h1 className="text-capitalize text-dark mb-4 error-page">Page Not Found!</h1>
                                <p className="text-dark para-desc mx-auto">The page you are looking for does not exist.</p>
                                <p className="text-dark para-desc mx-auto">If you think this is an error, contact us at <span className="text-primary font-weight-bold"><a href="mailto:contact@artysmm.com?subject=404 Page Not Found Issue Report">contact@artysmm.com</a></span>.</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12" className="text-center">
                                <button className="btn btn-outline-primary mt-4" onClick={this.goBack}>Go Back</button>
                                <Link to="/" className="btn btn-primary mt-4 ml-2">Homepage</Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
export default PageError;
