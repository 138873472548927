import React from 'react';

// Home
const Home = React.lazy(() => import('./pages/Home/index'));

//Services
const ServicesHome = React.lazy(() => import('./pages/Services/index'));
const ServicePage = React.lazy(() => import('./pages/Services/ServiceIndex'));

//Insights
const InsightsHome = React.lazy(() => import('./pages/Insights/Home/index'));
const InsightsList = React.lazy(() => import('./pages/Insights/ListPage'));
const MainCategoryPage = React.lazy(() => import('./pages/Insights/MainCategory/MainCategoryPage'));
const ArticlePage = React.lazy(() => import('./pages/Insights/Article/ArticlePage'));
const OurPeoplePage = React.lazy(() => import('./pages/Insights/People/OurPeoplePage'));
const PersonPage = React.lazy(() => import('./pages/Insights/People/PersonPage'));
const CategoriesPage = React.lazy(() => import('./pages/Insights/Category/CategoriesListPage'));
const CategoryPage = React.lazy(() => import('./pages/Insights/Category/CategoryPage'));
const TagsPage = React.lazy(() => import('./pages/Insights/Tag/TagsListPage'));
const TagPage = React.lazy(() => import('./pages/Insights/Tag/TagPage'));
const SearchPage = React.lazy(() => import('./pages/Insights/Search/SearchPage'));

//Contact
const PageContact = React.lazy(() => import('./pages/Pages/Contact/PageContact'));

//FAQ
const PageFAQ = React.lazy(() => import('./pages/Pages/PageFAQ'));

//Other
const PageTerms = React.lazy(() => import('./pages/Pages/PageTerms'));
const PagePrivacy = React.lazy(() => import('./pages/Pages/PagePrivacy'));

//Error
const PageError = React.lazy(() => import('./pages/Pages/PageError'));


const routes = [

    //Services
    { path: '/services/:slug/', component: ServicePage },
    { path: '/services/', component: ServicesHome },

    //Pages
    { path: '/contact/', component: PageContact },
    { path: '/faq/', component: PageFAQ },
    { path: '/terms-conditions/', component: PageTerms },
    { path: '/privacy-policy/', component: PagePrivacy },

    //Insights
    { path: '/insights/list/', component: InsightsList },
    { path: '/insights/', component: InsightsHome },
    { path: '/our-people/:username/', component: PersonPage },
    { path: '/our-people/', component: OurPeoplePage },
    { path: '/categories/:slug/', component: CategoryPage },
    { path: '/categories/', component: CategoriesPage },
    { path: '/tags/:slug/', component: TagPage },
    { path: '/tags/', component: TagsPage },
    { path: '/search/', component: SearchPage },
    { path: '/:main_category/:slug/', component: ArticlePage },
    { path: '/:main_category/', component: MainCategoryPage },

    //Root
    { path: '/', exact: true, component: Home },

    //Error
    { component: PageError },

];

export default routes;
